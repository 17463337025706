.course_details {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.nav_path {
    width: 90%;
    margin-top: 20px;
    margin-bottom: 20px;
}
.text1 {
    font-size: 18px;
    font-family: Montserrat;
    color: gray;
}
.text2{
    font-size: 18px;
    font-family: Montserrat;
}
.course_content1{
    background-color: black;
    width: 100%;
    height: 170px;
    color: white;
}
.text3 {
    font-size: 18px;
    font-family: Montserrat;
    margin-top: 25px;
    background-color: black;
    margin-left: 120px;
    width: 400px;
    font-weight: 550;
    line-height: 18px;
}
.text4 {
    margin-left: 120px;
    font-size: 12px;
    font-family: Montserrat;
    margin-top: 5px;
    background-color: black;
}
.text5 {
    font-size: 12px;
    font-family: Montserrat;
    background-color: black;
    color: #FF9271;
    margin-left: 120px;
    margin-top: 25px;
}
.course_content_buttons {
    background-color: black;
    margin-left: 120px;
    margin-top: 5px;
}
.button1 {
    font-size: 10px;
    border: 0px;
    color: black;
    background: white;
    border-radius: 4px;
}
.course_content2 {
    width: 60%;
    border: 1px solid #E0E0E0;
    opacity: 1;
    margin-right: 100px;
    margin-top: 20px;
}
.content2_video {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.text6 {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    margin-left: 5px;
    margin-top: 5px;
}
.text7 {
    font-size: 14px;
    margin-top: 10px;
    margin-left: 5px;
    font-family: Montserrat;
}
.course_content3 {
    width: 35%;
    height: 325px;
    border: 1px solid white;
    margin-left: 500px;
    position: absolute;
}
.video {
    background-color: black;
    height: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.play_button {
    height: 40px;
}

.below_video {
    width: 100%;
    height: 140px;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border: 1px solid #E0E0E0;
    opacity: 1;
}

.text8 {
    font-family: Montserrat;
    width: 100%;
    font-size: 20px;
    margin-top: 5px;
    font-weight: 600;
    margin-left: 5px;
}
.text9 {
    font-family: Montserrat;
    width: 100%;
    font-size: 16px;
    margin-left: 5px;
}
.text10 {
    margin-top: 10px;
    font-family: Montserrat;
    width: 100%;
    font-size: 16px;
    color: #D13030;
    margin-left: 5px;
}
.two_btn {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 20px;
}

.button2 {
    width: 160px;
    height: 35px;
    margin-left: 15px;
    color: white;
    background: #FF6738;
    border: 0px;
    border-radius: 4px;
}

.button3 {
    width: 160px;
    height: 35px;
    border: 1px solid #FF6738;
    color: #FF6738;
    background: white;
    border-radius: 4px;
}