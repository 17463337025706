*{
    background: #F5F5F5 0% 0% no-repeat padding-box;
    opacity: 1;
}

.header {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    width: 100%;
    height: 71px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.header_logo {
    width: 100px;
    margin-top: 18px;
    margin-bottom: 18px;
    margin-left: 10px;
    object-fit: contain;
    
}

.header_text {
    color: black;
    margin: 0;
    padding-right: 20px;
    display: flex;
    align-items: center;
}
.header_left {
    width: 30%;
}

.header_right {
    width: 70%;
    display: flex;
    justify-content: flex-end;
}

