.move_to_wishlist{

}

.card_left {
    display: flex;
}

.right_tab {
    display: flex;
    border-radius: 3px;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    margin-top: 35px;
    margin-left: 10px;
    width: 200px;
    align-items: flex-start;
    height: 122px;
    flex-direction: column;
}
.line1 {
    font-size: 12px;
    margin-left: 5px;
    margin-top: 10px;
}
.line2 {
    margin-top: 10px;
    margin-left: 5px;
    font-weight: 600;
}
.line3 {
    margin-left: 5px;
    margin-top: 3px;
    font-size: 9px;
    color: green;
}
.line4 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.checkout_btn {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 12px;
    border: 1px solid black;
    color: white;
    height: 30px;
    background: #FF6738 0% 0% no-repeat padding-box;
    opacity: 1;
}

.overlay{
    position: fixed;
    top:0px;
    left:0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0,0,0,0.8);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0px;
}

.overlay:target{
    visibility: visible;
    opacity: 1;
}
.add_to_wishlist_text {
    color: green;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
}

.pop_up {
    height: 150px;
    width: 500px;
    border-radius: 2px;
    margin-left: 25%;
    margin-top: 25%;
}
.top_header {
    display: flex;
    background: #8080804d;
    opacity: 1;
    height: 30px;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
   
}
.ok_button {
    height: 30px;
    width: 100px;
    background: #ff6600;
    border: 0;
    border-radius: 2px;
    margin-left: 38%;
}

.course_card_checkout {
    display: flex;
    border-radius: 3px;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    height: 70px;
    margin-top: 10px;
    margin-left: 10px;
    font-size: 13px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}